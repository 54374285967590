import Character from "../character/character";
import Comingsoon from "../comingsoon/comingsoon";
import Contactus from "../contactus/contactus";
import Footer from "../footer/footer";
import Gamemodes from "../gamemodes/gamemodes";
import Header from "../header/header";
import Mainsection from "../mainsection/mainsection";
import PageTitle from "../pagetitle";
import Playgame from "../playgame/playgame";
import Slider3D from "../slider/slider3D";
import Team from "../team/team";
import Watchtrailer from "../watchtrailer/watchtrailer";

function Home() {
  return (
    <>
      <div>
        <PageTitle title="CowParadise" />
        <Header />
        <Mainsection />
        <Watchtrailer />
        <Gamemodes />
        <Playgame />
        <Character />
        <Comingsoon />
        <Team />
        <Contactus />
        <Footer />
      </div>
    </>
  );
}

export default Home;
